<template>
  <b-card-code title="บันทึกเลขผู้สัมผัสเสี่ยงสูง รายใหม่">
    <validation-observer ref="highPossibilityNovelForm">
      <b-form @submit.prevent>
        <b-row>
          <b-col lg="6">
            <b-row>
              <!-- id card -->
              <b-col cols="12">
                <b-form-group
                  label="เลขบัตรประจำตัวประชาชน"
                  label-for="v-id-card"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="เลขบัตรประจำตัวประชาชน"
                    rules="length:13|regex:^([0-9]+)$"
                  >
                    <b-form-input
                      id="v-id-card"
                      v-model="form.idCard"
                      placeholder="เลขบัตรประจำตัวประชาชน"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- passport id -->
              <b-col cols="12">
                <b-form-group
                  label="เลข Passport"
                  label-for="v-passport-id"
                >
                  <b-form-input
                    id="v-passport-id"
                    v-model="form.passportId"
                    placeholder="เลข Passport"
                  />
                </b-form-group>
              </b-col>
              <!-- code -->
              <b-col cols="12">
                <b-form-group
                  label="Code"
                  label-for="v-code"
                >
                  <b-form-input
                    id="v-code"
                    v-model="form.patientCode"
                    placeholder="Code"
                  />
                </b-form-group>
              </b-col>
              <!-- name -->
              <b-col cols="12">
                <b-form-group
                  label="ชื่อ"
                  label-for="v-name"
                >
                  <b-form-input
                    id="v-name"
                    v-model="form.name"
                    placeholder="ชื่อ"
                  />
                </b-form-group>
              </b-col>
              <!-- name -->
              <b-col cols="12">
                <b-form-group
                  label="นามสกุล"
                  label-for="v-lastName"
                >
                  <b-form-input
                    id="v-lastName"
                    v-model="form.lastName"
                    placeholder="นามสกุล"
                  />
                </b-form-group>
              </b-col>
              <!-- name -->
              <b-col cols="12">
                <b-form-group
                  label="เบอร์โทรศัพท์"
                  label-for="v-mobileNumber"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="เบอร์โทรศัพท์"
                    rules="required|min:8"
                  >
                    <b-form-input
                      id="v-mobileNumber"
                      v-model="form.mobileNumber"
                      placeholder="เบอร์โทรศัพท์"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <!-- document image -->
              <b-col cols="12">
                <b-form-group
                  label="ภาพ บัตรประจำตัวประชาชน / Passport"
                  label-for="v-id-card"
                >
                  <b-form-file
                    v-model="form.personalDocumentImage"
                    accept="image/*"
                  />

                  <div v-if="previewUploadImage">
                    <b-button
                      size="sm"
                      block
                      variant="outline-primary"
                      @click="form.personalDocumentImage = null"
                    >
                      ลบรูป
                    </b-button>
                    <div class="text-center mt-2">
                      <b-img
                        :src="previewUploadImage"
                        fluid
                      />
                    </div>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <!-- submit and reset -->
          <b-col cols="12">
            <div
              v-if="saving"
              class="text-center mt-2"
            >
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
                disabled
              >
                <b-spinner
                  class="spinner-border-sm"
                  label="กำลังบันทึก..."
                />
                <span class="ml-1">กำลังบันทึกข้อมูล</span>
              </b-button>
            </div>
            <div
              v-else
              class="text-center mt-2"
            >
              <b-button
                type="submit"
                variant="success"
                class="mr-1"
                :disabled="saving"
                @click="submitForm"
              >
                บันทึกข้อมูล
              </b-button>
              <b-button
                type="reset"
                variant="outline-secondary"
                :disabled="saving"
                @click="resetForm"
              >
                ล้าง
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormFile, BImg, BSpinner,
} from 'bootstrap-vue'
import axios from '@/libs/axios'
import errorHandleMixins from '@/mixins/error'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, length, between, regex,
} from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BCardCode,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormFile,
    BImg,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [errorHandleMixins],
  data() {
    return {
      saving: false,
      form: {
        name: '',
        lastName: '',
        idCard: '',
        passportId: '',
        patientCode: '',
        personalDocumentImage: null,
      },
      initialForm: {
        name: '',
        lastName: '',
        idCard: '',
        passportId: '',
        patientCode: '',
        personalDocumentImage: null,
      },
      required,
      length,
      between,
      regex,
    }
  },
  computed: {
    previewUploadImage() {
      if (this.form.personalDocumentImage) {
        return URL.createObjectURL(this.form.personalDocumentImage)
      }
      return null
    },
  },
  methods: {
    resetForm() {
      this.form = { ...this.initialForm }
      this.$refs.highPossibilityNovelForm.reset()
    },
    async submitForm() {
      const result = await this.$refs.highPossibilityNovelForm.validate()
      if (!result) { return }

      if (!this.form.idCard && !this.form.passportId && !this.form.patientCode) {
        this.$swal({
          icon: 'error',
          title: 'กรุณาระบุข้อมูล เลขบัตรประจำตัวประชาชน / Passport / Code อย่างน้อยอย่างใดอย่างหนึ่ง',
          confirmButtonText: 'ตกลง',
        })
        return
      }

      try {
        this.saving = true
        let documentImageId = null
        if (this.form.personalDocumentImage) {
          const formData = new FormData()
          formData.append('file', this.form.personalDocumentImage)

          const { data } = await axios.post('/upload/document-images', formData)
          // eslint-disable-next-line no-underscore-dangle
          documentImageId = data && data[0] && data[0]._id ? data[0]._id : null
        }

        await axios.post('/novels/high-possibility-submit', {
          name: this.form.name,
          lastName: this.form.lastName,
          idCard: this.form.idCard,
          passportId: this.form.passportId,
          patientCode: this.form.patientCode,
          mobileNumber: this.form.mobileNumber,
          documentImageId,
        })
        this.$swal({
          icon: 'success',
          title: 'สำเร็จ',
          text: 'บันทึกข้อมูลสำเร็จ',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        this.saving = false
        this.resetForm()
      } catch (e) {
        this.saving = false
        this.$errorHandler(e)
      }
    },
  },
}
</script>

<style>

</style>
